<template>
  <div class="phase3">
    <vue-gallery
      id="gallery-phase4-new"
      :images="images"
      :index="index"
      @close="index = null"
    ></vue-gallery>
    <div>
      <div class="bsat__title">{{ $t("title") }}</div>

      <!-- *********************** PARAGRAPH *********************** -->
      <section>
        <div class="bsat__paragraph">{{ $t("paragraph") }}</div>

        <!-- <ReadMore>
          <div>
            <i18n path="paragraph2.text" tag="div" class="bsat__paragraph">
              <template #link>
                <a @click="goToTab(3)">{{ $t("paragraph2.link") }}</a>
              </template>
            </i18n>
          </div>

        </ReadMore> -->
        <div class="bsat__paragraph">{{ $t("paragraph2") }}</div>
      </section>

      <!-- *********************** WHO TO INVOLVE **************************** -->
      <section>
        <div class="bsat__subtitle mb-4">{{ $t("whoToInvolve.title") }}</div>
        <div>
          <i18n
            path="whoToInvolve.paragraph.text"
            tag="div"
            class="bsat__paragraph"
          >
            <template #link1>
              <a @click="goToTab(3)">{{
                $t("whoToInvolve.paragraph.link1")
              }}</a>
            </template>
          </i18n>
        </div>

        <!-- <div class="bsat__paragraph">{{ $t("paragraph3") }}</div> -->
      </section>
    </div>

    <!-- ***************** STEPS ****************** -->
    <div class="steps">
      <div class="bsat__subtitle">{{ $t("stepsTitle") }}</div>
      <v-stepper class="elevation-0 bg-off_white" v-model="steps">
        <!-- ************* STEPPER HEADER **************** -->
        <v-stepper-header
          :style="{ maxWidth: $vuetify.breakpoint.smAndUp ? '50%' : '' }"
        >
          <v-stepper-step @click="steps = 1" step="1"> </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step @click="steps = 2" step="2"> </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step @click="steps = 3" step="3"> </v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step @click="steps = 4" step="4"> </v-stepper-step>
        </v-stepper-header>

        <!-- ***************** STEPPER CONTENT ***********************-->
        <v-stepper-content
          v-for="(step, i) in phase3"
          :key="i"
          :step="i + 1"
          class="steps__content px-0"
        >
          <keep-alive>
            <component :is="step.component"></component>
          </keep-alive>
        </v-stepper-content>
      </v-stepper>
    </div>

    <Divider></Divider>

    <div class="bsat__subtitle">{{ $t("power4BioExperience") }}</div>
    <!--************************* TABS **************************** -->
    <div class="phase3__tabs">
      <v-tabs
        v-model="tab"
        class="bsat__tabs"
        background-color="transparent"
        slider-color="dark"
        color="dark"
        show-arrows
        center-active
      >
        <v-tab
          v-for="(item, i) in footerTabs"
          :key="i"
          class="mr-4 mr-sm-8"
          :style="
            $vuetify.breakpoint.xsOnly ? 'min-width: 90px' : 'min-width: 160px'
          "
        >
          {{ $t(item.tab) }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="py-4">
        <!-- ********** REAL EXAMPLES TAB ******* -->
        <v-tab-item>
          <div class="bg-off_white">
            <div class="bsat__paragraph">
              {{ $t("realExamples.paragraph") }}
            </div>
            <!-- <ul>
              <li v-for="(item, i) in examples" :key="i">
                <div class="bsat__paragraph">
                  <strong>{{ item.title }}</strong>
                </div>
                <a :href="realExamplesFiles[i]" target="_blank" download>
                  <v-btn
                    class="bsat__btn"
                    color="dark"
                    dark
                    depressed
                    width="150"
                    height="26"
                  >
                    {{ $t("download") }}
                  </v-btn>
                </a>
              </li>
            </ul> -->

            <v-row v-for="(item, i) in examples" :key="i" align="center">
              <v-col cols="12" md="3">
                <v-img
                  class="elevation-2 rounded"
                  style="height: 200px !important"
                  :src="require(`@/assets/icons/regions/${item.icon}`)"
                  @click="index = i"
                ></v-img>
              </v-col>
              <v-col cols="12" md="9">
                <div class="bsat__paragraph">
                  <strong>{{ item.title }}</strong>
                </div>
                <a :href="realExamplesFiles[i]" target="_blank" download>
                  <v-btn
                    class="bsat__btn"
                    color="dark"
                    dark
                    depressed
                    width="150"
                    height="26"
                  >
                    {{ $t("download") }}
                  </v-btn>
                </a>
              </v-col>
            </v-row>
          </div>
        </v-tab-item>
        <!-- ********** NOTES TAB ******* -->
        <v-tab-item>
          <div class="bg-off_white">
            <div class="bsat__subtitle">{{ $t("notes.note1.title") }}</div>
            <div class="bsat__image">
              <img src="@/assets/avatars/1.png" />
            </div>
            <div class="bsat__paragraph">
              {{ $t("notes.note1.paragraph") }}
            </div>
            <div class="mb-4">
              <ul>
                <li
                  v-for="(item, i) in note1UnorderedList"
                  :key="i"
                  v-text="item"
                ></li>
              </ul>
            </div>

            <div class="bsat__subtitle">{{ $t("notes.note2.title") }}</div>
            <div class="bsat__image">
              <img src="@/assets/avatars/2.png" />
            </div>
            <div class="bsat__paragraph">
              {{ $t("notes.note2.paragraph") }}
            </div>

            <div class="bsat__subtitle">{{ $t("notes.note3.title") }}</div>
            <div class="bsat__image mb-2">
              <div class="row align-center">
                <div class="col-md-2 col-12">
                  <img src="@/assets/avatars/13.jpg" />
                </div>
                <div class="col-md-3 col-12">
                  {{ $t("notes.note3.partner1") }}
                </div>
              </div>
              <div class="row align-center">
                <div class="col-md-2 col-12">
                  <img src="@/assets/avatars/3.jpg" />
                </div>
                <div class="col-md-3 col-12">
                  {{ $t("notes.note3.partner2") }}
                </div>
              </div>
              <div class="row align-center">
                <div class="col-md-2 col-12">
                  <img src="@/assets/avatars/4.jpg" />
                </div>
                <div class="col-md-3 col-12">
                  {{ $t("notes.note3.partner3") }}
                </div>
              </div>
              <div class="row align-center">
                <div class="col-md-2 col-12">
                  <img src="@/assets/avatars/5.png" />
                </div>
                <div class="col-md-3 col-12">
                  {{ $t("notes.note3.partner4") }}
                </div>
              </div>
              <div class="row align-center">
                <div class="col-md-2 col-12">
                  <img src="@/assets/avatars/6.jpg" />
                </div>
                <div class="col-md-3 col-12">
                  {{ $t("notes.note3.partner5") }}
                </div>
              </div>
            </div>

            <div class="bsat__paragraph">
              {{ $t("notes.note3.paragraph1") }}
            </div>
            <div class="bsat__paragraph bold">
              {{ $t("notes.note3.subtitle1") }}
            </div>
            <div class="bsat__paragraph">
              {{ $t("notes.note3.paragraph2") }}
            </div>
            <div class="bsat__paragraph bold">
              {{ $t("notes.note3.subtitle2") }}
            </div>
            <div class="bsat__paragraph">
              {{ $t("notes.note3.paragraph3") }}
            </div>

            <div class="bsat__subtitle">{{ $t("notes.note4.title") }}</div>
            <div class="bsat__image mb-2">
              <div class="row align-center">
                <div class="col-md-2 col-12">
                  <img src="@/assets/avatars/14.jpg" />
                </div>
                <div class="col-md-3 col-12">
                  {{ $t("notes.note4.partner1") }}
                </div>
              </div>
              <div class="row align-center">
                <div class="col-md-2 col-12">
                  <img src="@/assets/avatars/7.jpg" />
                </div>
                <div class="col-md-3 col-12">
                  {{ $t("notes.note4.partner2") }}
                </div>
              </div>
              <div class="row align-center">
                <div class="col-md-2 col-12">
                  <img src="@/assets/avatars/8.png" />
                </div>
                <div class="col-md-3 col-12">
                  {{ $t("notes.note4.partner3") }}
                </div>
              </div>
              <div class="row align-center">
                <div class="col-md-2 col-12">
                  <img src="@/assets/avatars/9.jpg" />
                </div>
                <div class="col-md-3 col-12">
                  {{ $t("notes.note4.partner4") }}
                </div>
              </div>
              <div class="row align-center">
                <div class="col-md-2 col-12">
                  <img src="@/assets/avatars/10.jpg" />
                </div>
                <div class="col-md-3 col-12">
                  {{ $t("notes.note4.partner5") }}
                </div>
              </div>
            </div>

            <div class="bsat__paragraph">
              {{ $t("notes.note4.paragraph1") }} <br />
              {{ $t("notes.note4.paragraph2") }} <br />
              {{ $t("notes.note4.paragraph3") }} <br />
              {{ $t("notes.note4.paragraph4") }}
            </div>

            <div class="bsat__subtitle">{{ $t("notes.note5.title") }}</div>
            <div class="bsat__paragraph">
              {{ $t("notes.note5.paragraph") }}
            </div>
          </div>
        </v-tab-item>

        <!-- ********** OPINIONS TAB ******* -->
        <!-- <v-tab-item>
          <v-card flat color="off_white">
            <v-card-text class="px-0">
              <v-list dense class="bg-off_white">
                <v-list-item
                  dense
                  class="text-default"
                  v-for="(item, i) in currentOpinions"
                  :key="i"
                >
                  <v-list-item-avatar size="40">
                    <v-img src="@/assets/icons/profile.png"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.subtitle
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-row>
                <v-spacer></v-spacer>
                <v-pagination
                  class="pagination"
                  color="dark"
                  v-model="page"
                  :length="2"
                ></v-pagination>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item> -->
      </v-tabs-items>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "notes": {
        "tabTitle": "Notes",
        "note1": {
            "title": "Lviv, Orest Kiyko",
            "paragraph": "Position/role in the development of the regional bioeconomy strategy - Head of the Furniture Production Techniques and Wood Product Technology Department of the Ukrainian National Forestry University, Professor, Doctor of Science / Coordinator of the regional team in reference to the bioeconomy strategy elaboration for the Lviv region of Ukraine.",
            "unorderedList": [
                "Involving a lot of stakeholders in the process of the bioeconomy strategy development.",
                "It was made detailed analysis of the bioconomy potential of the Lviv region.",
                "“Bioeconomy cluster” creation.",
                "It was accepted that bioeconomy is a Smart Specialization of the Lviv region.",
                "It was presented idea in reference to the bioeconomy development at the national level."
            ]
        },
        "note2": {
            "title": "Mazovia, Marek Pszonka",
            "paragraph": "Position/role in the development of the regional bioeconomy strategy: Member of the Board, Mazowiecka Agencja Energetyczna Sp. z o.o. The main complementary achievement of the POWER4BIO project in Mazovia, to the development of a bioeconomy development strategy, is the commencement of work on a new perspective for the bioeconomy program, which is to put more emphasis on activities and financing tasks in the field of bioeconomy. POWER4BIO has resulted in a shift towards a more proactive bioeconomy policy."
        },
        "note3": {
            "title": "South Bohemia",
            "paragraph1": "Experience with successful regions has not answered the question of how to realistically solve the situation in a region where sufficient funding is not available (public or private) and there is considerable risk aversion across companies that could potentially be direct participants in bioeconomy development in the region. Lastly, the analysis of the regional potential brought a lot of information, however, it is necessary to specialize closely and create projects on specific topics with interested partners to achieve the final application in the region.",
            "paragraph2": "Interactive Strategy Toolkit enabled us to present it to representatives from regional management and also on national level to deputy ministers (Agriculture, Regional and Environment) to support the idea that regional and national strategy must be created hand in hand.",
            "paragraph3": "Regional businesses were reluctant to start new bioeconomic activity without any kind of economic support or incentives giving them the information that bioeconomy will be a new way of future development.",
            "subtitle1": "A good experience:",
            "subtitle2": "A bad experience which might swift to a recommendation:.",
            "partner1": "Eva Cudlínová, coordinator of USB project team",
            "partner2": "Roman Buchtele",
            "partner3": "Nikola Sagappová",
            "partner4": "Iveta Šindelářová",
            "partner5": "Zuzana Dvořáková –Líšková"
        },
        "note4": {
            "title": "Nitra",
            "paragraph1": "Position/role in the development of the regional bioeconomy strategy: Section of strategic activities, Head of Department of strategy and regional development programmes (Nitra Self-Governing region)",
            "paragraph2": "Position/role in the development of the regional bioeconomy strategy: Department of project management and external relations, National Agricultural and Food Centre; BIOEAST national contact point",
            "paragraph3": "Position/role in the development of the regional bioeconomy strategy: Bioeconomy Cluster",
            "paragraph4": "The P4B project has strongly contributed to define few objectives for further development of our region which can give us a comparative advantage. It also made us sure that the participatory approach in strategy development is the most efficient. Also, Bioeconomy Cluster has been a key stakeholder in the process of negotiating importance of bioeconomy issues in the process of strategies development at national and regional level since 2015. Within formulation of strategic bioeconomy priorities for the Nitra region, we have learned that the essence of the successful strategy is also the ability to choose what not to do. Although it seems very nice, we need a good implementation tactics and efficient monitoring system now. The P4B project has provided us with a lot of knowledge how to do it.",
            "partner1": "Danka Moravčíková, Director of the Office of Project and Transfer Activities, Slovak University of Agriculture in Nitra/Coordinator of the regional team",
            "partner2": "Viera Juricová-Melušová. Head of Department of strategy and regional development programmes (Nitra Self-Governing region)",
            "partner3": "Dana Peškovičová. Department of project management and external relations, National Agricultural and Food Centre; BIOEAST national contact point",
            "partner4": "Katarína Blicklingová. Bioeconomy Cluster",
            "partner5": "Daniel Ács. Bioeconomy Cluster"
        },
        "note5": {
            "title": "South Great Plain",
            "paragraph": "The main achievement of the Hungarian bioeconomy is the establishment of the Hungarian Bioeconomy Cluster. Thanks to the POWER4BIO project, the Cluster started to operate at a high speed, members received important information, participated in meaningful events and had the opportunity to get a hint of the European bioeconomy. The highly committed staff of the Cluster will continue their work for the Hungarian bioeconomy after the end of the project."
        }
    },
    "opinions": {
      "list": [
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 1"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 2"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 3"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 4"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 5"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 6"
        }
      ],
      "title": "Opinions"
    },
    "orderedList": [
      {
        "title": "Representatives from the formed Interministerial/interdepartmental group, which has previously signalize their agreement to work together towards the strategy development. Within this group is common to have two subgroups:",
        "unorderedList": [
          "The decision-level: policy-makers within involved ministries or regional departments. The ones appointed to this group have as role to take the main decisions and approve several aspects within the process. For instance, approve the designed participatory approach with regional stakeholders or revise and approve the draft of the strategy. ",
          "The technical-level: technical experts within ministries or regional departments, that will validate collected knowledge during participatory process and will draft the document of the strategy. This group will have constant communication with the decision-level for feedback and advice."
        ]
      },
      {
        "title": "Advisory group: formed by experts of diverse areas, called by the Interministerial/interdepartmental group to enrich the process and help in some decision making. Ideally the RBH should already have involved these experts in preparation for the strategy development, in which case the advisory group would be derived from the RBH. However, it is also possible that the necessary experts are engaged partly from the RBH and partly individually.  "
      },
      {
        "title": "Leaders of participatory process: group or institution in charge of designing and carrying out the participatory process with the supervision of the Interministerial/interdepartmental group. It is usual for some regions to include for this role an independent consultancy or company. The RBH will be fundamental in this step, since it represents at this point a platform to reach several stakeholders of the regional bioeconomy community. "
      }
    ],
    "orderedListTitle": "Usually, the strategy development working group comprise:  ",
    "paragraph": "The following steps for the roadmap definition have been drawn based on the POWER4BIO project experience and literature review. However, the development process could be adapted to the respective starting conditions.",
    "paragraph2": "In this phase, steps will be detailed for the definition of a roadmap that operationalises the region-al bioeconomy strategy.",
    "power4BioExperience": "The POWER4BIO experience",
    "realExamples": {
      "examples": [
        {
          "icon": "LVIV.jpg",
          "title": "Regional Bieconomy Implementation plan in Lviv (Ukraine)"
        },
        {
          "icon": "MAZOVIA.jpg",
          "title": "Regional Bieconomy Implementation plan in Mazovia (Poland)"
        },
        {
          "icon": "NITRA.jpg",
          "title": "Regional Bieconomy Implementation plan in Nitra (Slovakia)"
        },
        {
          "icon": "SOUTH_BOHEMIA.jpg",
          "title": "Regional Bieconomy Implementation plan in South Bohemia (Czech Republic)"
        },
        {
          "icon": "SOUTHERN_GREAT_PLAIN.jpg",
          "title": "Regional Bieconomy Implementation plan in Southern Great Plain (Hungary)"
        }
      ],
      "paragraph": "Check the 5 Regional Bioeconomy implementation plans developed by the Central and Eastern Europe regions of POWER4BIO: ",
      "tabTitle": "Real Examples"
    },
    "stepsTitle": "Overview of the steps",
    "title": "Phase 4: Roadmap definition ",
    "whoToInvolve": {
      "paragraph": {
        "link1": "Phase 3",
        "text": "It is recommended to involve the same actors that during Phase 3. The specific moment to engage them for the roadmap definition and its regularity should be defined in the planning of the participatory process (step 1 in {link1}). Also, if necessary, it needs to be updated after the lessons learnt in Phase 3."
      },
      "title": "Who to involve in this phase?"
    }
  }
}
</i18n>

<script>
import { EventBus } from "@/utils/EventBus";
import { getArrayPiece } from "@/utils/helpers";
import Divider from "@/components/Divider";
import VueGallery from "vue-gallery";

// import ReadMore from "@/components/ReadMore";

export default {
  name: "Phase4",
  components: {
    Step1: () => import("./Step1"),
    Step2: () => import("./Step2"),
    Step3: () => import("./Step3"),
    Step4: () => import("./Step4"),
    Divider,
    VueGallery
    // ReadMore,
  },
  data() {
    return {
      steps: 1,
      tab: 0,
      step2Tab: 0,
      page: 1,
      faoLink:
        "http://www.fao.org/climate-change/our-work/areas-of-work/bioeconomy/en/",
      scarLink: "https://www.scar-swg-sbgb.eu/the-bioeconomy",
      euCommisionLink: "https://ec.europa.eu/research/bioeconomy/index.cfm",
      footerTabs: [
        { tab: "realExamples.tabTitle" },
        { tab: "notes.tabTitle" }
        // { tab: "opinions.title" }
      ],
      phase3: [
        { component: "Step1" },
        { component: "Step2" },
        { component: "Step3" },
        { component: "Step4" }
      ],
      realExamplesFiles: [
        require("@/assets/files/Roadmap_Lviv_Region.docx").default,
        require("@/assets/files/Roadmap_Mazovia_Region.docx").default,
        require("@/assets/files/Roadmap_Nitra_Region.docx").default,
        require("@/assets/files/Roadmap_South_Bohemia_Region.docx").default,
        require("@/assets/files/Roadmap_Southern_Great_Plain_Region.docx")
          .default
      ],
      index: 0,
      images: [
        require("@/assets/icons/regions/LVIV.jpg"),
        require("@/assets/icons/regions/MAZOVIA.jpg"),
        require("@/assets/icons/regions/NITRA.jpg"),
        require("@/assets/icons/regions/SOUTH_BOHEMIA.jpg"),
        require("@/assets/icons/regions/SOUTHERN_GREAT_PLAIN.jpg")
      ]
    };
  },
  computed: {
    orderedList() {
      return Object.values(this.$t("orderedList"));
    },
    opinions() {
      return Object.values(this.$t("opinions.list"));
    },
    examples() {
      return Object.values(this.$t("realExamples.examples"));
    },
    currentOpinions() {
      return getArrayPiece(this.opinions, this.page, 3);
    },
    // step2PolicyAnalysisMaterial() {
    //   return this.$t("step1.policyAnalysis.Materialparagraph").split(":");
    // },
    note1UnorderedList() {
      return Object.values(this.$t("notes.note1.unorderedList"));
    }
  },
  watch: {
    steps(val) {
      if (window) {
        const url = new URL(window.location);
        url.searchParams.set("step", val);
        window.history.pushState({}, "", url);
      }
    }
  },
  mounted() {
    const query = this.$route.query;
    if (query.step) {
      this.steps = Number(query.step);
    }

    EventBus.$on("goToStep", stepNumber => {
      this.steps = stepNumber;
    });
  },
  methods: {
    getArrayFromObject(arr) {
      return Object.values(arr);
    },
    goToTab(tab) {
      EventBus.$emit("goToTab", tab);
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/main";

.phase3 {
  margin-top: 50px;

  .v-window {
    background-color: $off_white;
  }

  .pagination {
    .v-pagination__item {
      background: $inactive_grey;
      min-width: 18px !important;
      height: 20px !important;
      font-size: 10px;
      margin: 0px 3px;
      box-shadow: none !important;
    }
    .v-pagination__navigation {
      background: $inactive_grey;
      min-width: 18px !important;
      width: 18px !important;
      height: 20px !important;
      font-size: 10px;
      box-shadow: none !important;
    }
    .v-pagination__item .v-pagination__item--active {
      box-shadow: none;
    }
    .mdi-chevron-left {
      font-size: 12px;
    }
    .mdi-chevron-right {
      font-size: 12px;
    }
  }

  &__tabs {
    margin-top: 40px;
    margin-bottom: 200px;

    .bsat__paragraph {
      font-size: 14px;
      letter-spacing: 0;
    }

    .bsat__image img {
      object-fit: cover;
      width: 120px;
      height: 120px;
      margin: auto 2px;
    }
  }

  .v-tab {
    font-family: $font-family;
    text-transform: none;
    letter-spacing: normal;
    justify-items: start;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }

  .steps {
    &__title {
      font-family: $font-family;
      color: $light_green;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.6;
      letter-spacing: 0.2px;
      text-align: left;
    }

    &__content {
      background-color: #ffffff;
      border-radius: 8px;
      border: 1px solid $light_green;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }
    .v-stepper__header {
      background-color: $off_white;
      box-shadow: none;
    }

    .v-stepper__step--active {
      .v-stepper__step__step {
        align-items: center;
        border-radius: 50%;
        display: inline-flex;
        font-size: 16px;
        font-weight: bold;
        justify-content: center;
        height: 36px;
        min-width: 36px;
        width: 36px;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        background-color: $off_white !important;
        border: 4px solid $light_green !important;
        border-radius: 50%;
        color: $light_green !important;
      }
    }

    .v-stepper__step--inactive {
      .v-stepper__step__step {
        align-items: center;
        border-radius: 50%;
        display: inline-flex;
        font-size: 16px;
        font-weight: bold;
        justify-content: center;
        height: 36px;
        min-width: 36px;
        width: 36px;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        background-color: $off_white !important;
        border: 4px solid $inactive_grey !important;
        border-radius: 50%;
        color: $inactive_grey !important;
      }
    }

    .v-stepper__step__step {
      align-items: center;
      border-radius: 50%;
      display: inline-flex;
      font-size: 16px;
      font-weight: bold;
      justify-content: center;
      height: 36px;
      min-width: 36px;
      width: 36px;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      background-color: $off_white !important;
      border: 4px solid $inactive_grey !important;
      border-radius: 50%;
      color: $inactive_grey !important;
    }
    .v-stepper__step__step:hover {
      cursor: pointer;
    }
  }
}
</style>
